import React, { createContext, useCallback, useContext, useMemo } from "react";
import Incident from "./incident";
import useIncident from "./useIncident";
import usePantherIncident from "./usePantherEvent";
import {
  useAddComment,
  useAddPantherComment,
  useDeleteComment,
  useDeletePantherComment,
  useEditComment,
  useEditPantherComment,
  useGetComments,
  useGetPantherComments
} from "../api/comments";
import {
  useGetEvents,
  useGetPantherEvents,
  useDeleteAnalystNotes,
  useDeletePantherAnalystNotes,
  useUpdateAnalystNotes,
  useUpdatePantherAnalystNotes,
  useUpdateEvent,
  useUpdatePantherEvent
} from "../api/events";
import {
  ERROR_ICON,
  MESSAGE,
  SUCCESS_ICON,
  TEXT_WHITE_ICON
} from "../constants/common.constants";
import SnackbarContext from "../contexts/snackbar.context";

export const IncidentInfoProviderContext = createContext<any>({});

export function IncidentInformationProvider() {
  const getComments = useGetComments();
  const addComment = useAddComment();
  const deleteComment = useDeleteComment();
  const editComment = useEditComment();
  const getEvents = useGetEvents();
  const {
    id,
    incidentCatalog,
    created,
    handlerId,
    headerTitle,
    hostname,
    nextDisabled,
    previousDisabled,
    status,
    severities,
    orgName,
    loadingNewPage,
    isExempt,
    tags,
    isLoadingData,
    closeAllowed,
    sendToClientAllowed,
    sendToReviewAllowed,
    reassignAllowed,
    assignAllowed,
    initials,
    handlerName,
    notesDisabled,
    initialValues,
    validationSchema,
    categories,
    isAssigning,
    handleSendToClient,
    handleSendToReview,
    handleClose,
    handleAssign,
    handlePrevious,
    handleNext,
    handleSaveDescription,
    handleSaveTitle,
    handleSaveUsername,
    handleCategorySelected,
    handleSeveritySelected
  } = useIncident();
  const IncidentProviderData = useMemo(
    () => ({
      id,
      incidentCatalog,
      created,
      handlerId,
      headerTitle,
      hostname,
      nextDisabled,
      previousDisabled,
      status,
      severities,
      orgName,
      loadingNewPage,
      isExempt,
      tags,
      isLoadingData,
      closeAllowed,
      sendToClientAllowed,
      sendToReviewAllowed,
      reassignAllowed,
      assignAllowed,
      initials,
      handlerName,
      notesDisabled,
      initialValues,
      validationSchema,
      categories,
      isAssigning,
      handleSendToClient,
      handleSendToReview,
      handleClose,
      handleAssign,
      handlePrevious,
      handleNext,
      handleSaveDescription,
      handleSaveTitle,
      handleSaveUsername,
      handleCategorySelected,
      handleSeveritySelected,
      getComments,
      addComment,
      deleteComment,
      editComment,
      getEvents,
      isSIEM: false,
      useDeleteAnalystNotes,
      useUpdateAnalystNotes,
      useUpdateEvent
    }),
    [
      id,
      incidentCatalog,
      created,
      handlerId,
      headerTitle,
      hostname,
      nextDisabled,
      previousDisabled,
      status,
      severities,
      orgName,
      loadingNewPage,
      isExempt,
      tags,
      isLoadingData,
      closeAllowed,
      sendToClientAllowed,
      sendToReviewAllowed,
      reassignAllowed,
      assignAllowed,
      initials,
      handlerName,
      notesDisabled,
      initialValues,
      validationSchema,
      categories,
      isAssigning,
      handleSendToClient,
      handleSendToReview,
      handleClose,
      handleAssign,
      handlePrevious,
      handleNext,
      handleSaveDescription,
      handleSaveTitle,
      handleSaveUsername,
      handleCategorySelected,
      handleSeveritySelected,
      getComments,
      addComment,
      deleteComment,
      editComment,
      getEvents
    ]
  );
  return (
    <IncidentInfoProviderContext.Provider value={IncidentProviderData}>
      <Incident />
    </IncidentInfoProviderContext.Provider>
  );
}

export function PantherIncidentInformationProvider() {
  const getComments = useGetPantherComments();
  const addComment = useAddPantherComment();
  const deleteComment = useDeletePantherComment();
  const editComment = useEditPantherComment();
  const getEvents = useGetPantherEvents();
  const { showSnackbar, killSnackbar } = useContext(SnackbarContext);
  const {
    id,
    incidentCatalog,
    created,
    close_reason,
    close_final_disposition,
    handlerId,
    headerTitle,
    sensor,
    nextDisabled,
    previousDisabled,
    status,
    severities,
    orgName,
    loadingNewPage,
    isExempt,
    tags,
    isLoadingData,
    closeAllowed,
    sendToClientAllowed,
    sendToReviewAllowed,
    reassignAllowed,
    assignAllowed,
    initials,
    handlerName,
    notesDisabled,
    initialValues,
    validationSchema,
    categories,
    isAssigning,
    handleSendToClient,
    handleSendToReview,
    handleClose,
    handleAssign,
    handlePrevious,
    handleNext,
    handleSaveDescription,
    handleSaveTitle,
    handleSaveUsername,
    handleCategorySelected,
    handleSeveritySelected,
    handleUpdate,
    load,
    getData
  } = usePantherIncident();

  const submitIncident = useCallback(
    async (values: any) => {
      const snackbarKey = showSnackbar({
        text: "Updating incident...",
        type: MESSAGE.info,
        icon: TEXT_WHITE_ICON
      });

      try {
        const risk = values.category?.split("-")[0];
        await handleUpdate({ ...values, risk, user_name: values.username });
        load();
        getData();
        killSnackbar(snackbarKey);
        showSnackbar({
          text: "Event updated successfully ",
          type: MESSAGE.info,
          icon: SUCCESS_ICON
        });
      } catch (error: any) {
        console.error(
          `Error updating incident ${id}. Status ${error.status}. ${error}`
        );
        killSnackbar(snackbarKey);
        showSnackbar({
          text: "Error assigning category",
          type: MESSAGE.error,
          icon: ERROR_ICON
        });
      }
    },
    [getData, handleUpdate, id, killSnackbar, load, showSnackbar]
  );
  const useUpdatePantherAnalystNotesFunction = useUpdatePantherAnalystNotes(
    id || ""
  );
  const useDeletePantherAnalystNotesFunction = useDeletePantherAnalystNotes(
    id || ""
  );
  const useUpdateEventBookmarked = useUpdatePantherEvent(id || "");

  const IncidentProviderData = useMemo(
    () => ({
      id,
      incidentCatalog,
      created,
      close_reason,
      close_final_disposition,
      handlerId,
      headerTitle,
      sensor,
      nextDisabled,
      previousDisabled,
      status,
      severities,
      orgName,
      loadingNewPage,
      isExempt,
      tags,
      isLoadingData,
      closeAllowed,
      sendToClientAllowed,
      sendToReviewAllowed,
      reassignAllowed,
      assignAllowed,
      initials,
      handlerName,
      notesDisabled,
      initialValues,
      validationSchema,
      categories,
      isAssigning,
      handleSendToClient,
      handleSendToReview,
      handleClose,
      handleAssign,
      handlePrevious,
      handleNext,
      handleSaveDescription,
      handleSaveTitle,
      handleSaveUsername,
      handleCategorySelected,
      handleSeveritySelected,
      getComments,
      addComment,
      deleteComment,
      editComment,
      getEvents,
      isSIEM: true,
      submitIncident,
      useDeleteAnalystNotes: useDeletePantherAnalystNotesFunction,
      useUpdateAnalystNotes: useUpdatePantherAnalystNotesFunction,
      useUpdateEvent: useUpdateEventBookmarked
    }),
    [
      id,
      incidentCatalog,
      created,
      close_reason,
      close_final_disposition,
      handlerId,
      headerTitle,
      sensor,
      nextDisabled,
      previousDisabled,
      status,
      severities,
      orgName,
      loadingNewPage,
      isExempt,
      tags,
      isLoadingData,
      closeAllowed,
      sendToClientAllowed,
      sendToReviewAllowed,
      reassignAllowed,
      assignAllowed,
      initials,
      handlerName,
      notesDisabled,
      initialValues,
      validationSchema,
      categories,
      isAssigning,
      handleSendToClient,
      handleSendToReview,
      handleClose,
      handleAssign,
      handlePrevious,
      handleNext,
      handleSaveDescription,
      handleSaveTitle,
      handleSaveUsername,
      handleCategorySelected,
      handleSeveritySelected,
      getComments,
      addComment,
      deleteComment,
      editComment,
      getEvents,
      submitIncident,
      useDeletePantherAnalystNotesFunction,
      useUpdatePantherAnalystNotesFunction,
      useUpdateEventBookmarked
    ]
  );
  return (
    <IncidentInfoProviderContext.Provider value={IncidentProviderData}>
      <Incident />
    </IncidentInfoProviderContext.Provider>
  );
}
