import React, {
  useCallback,
  createContext,
  useState,
  useMemo,
  useEffect,
  useRef,
  useContext
} from "react";
import { useSelector } from "react-redux";
import {
  DATE_DEFAULT_VALUE,
  INCIDENT_FILTERS_MAP,
  PLUS_ONE_DAY_IN_SECONDS,
  SELECT_CLEAR_VALUE
} from "../constants/common.constants";
import FormatDate from "../formatDate/formatDate";
import {
  incidentFiltersMapper,
  incidentsFiltersParametersMapper
} from "../mappers/incidentListMappers";
import Permissions from "../permissions/permissions";
import { IProvider } from "../types/common.types";
import { IPermissions } from "../permissions/permissions.types";
import {
  IIncidentListAvailableFilters,
  IIncidentListCurrentFilters,
  IIncidentListFiltersContext
} from "./types/incident-list-filters.types";
import SessionContext from "./session.context";
import { useGetPantherIncidentsFilters } from "../api/incidents";

const IncidentPantherListFiltersContext =
  createContext<IIncidentListFiltersContext>({} as IIncidentListFiltersContext);

export function IncidentPantherListFiltersProvider({ children }: IProvider) {
  const localUser = useSelector((state: any) => state.user.profile);
  const today = useMemo(() => new FormatDate(), []);
  const getPantherAvailableFilters = useGetPantherIncidentsFilters();

  const { Incidents: permissions }: IPermissions = Permissions();
  const { isMdrRole } = useContext(SessionContext);
  const [searchText, setSearchText] = useState<string>("");
  const searchTextDebounce = useRef<any>(null);
  const firstTimeSwSearchText = useRef<boolean>(false);
  const dateDebounce = useRef<any>(null);
  const firstTimeSw = useRef<boolean>(false);
  const currentFiltersInitialState: IIncidentListCurrentFilters = useMemo(
    () => ({
      client: { id: null, caption: SELECT_CLEAR_VALUE },
      hostname: { id: null, caption: SELECT_CLEAR_VALUE },
      status: { id: null, caption: SELECT_CLEAR_VALUE },
      category: { id: null, caption: SELECT_CLEAR_VALUE },
      rules: { id: null, caption: SELECT_CLEAR_VALUE },
      escalated: { id: null, caption: SELECT_CLEAR_VALUE },
      event_types: { id: null, caption: SELECT_CLEAR_VALUE },
      creationStartDate: {
        epoch: null,
        caption: DATE_DEFAULT_VALUE
      },
      close_reason: { id: null, caption: SELECT_CLEAR_VALUE },
      creationEndDate: {
        epoch: today.epochDate + PLUS_ONE_DAY_IN_SECONDS,
        caption: today.date
      }
    }),
    [today]
  );
  const availableFiltersInitialState: IIncidentListAvailableFilters = useMemo(
    () => ({
      client: {},
      hostname: {},
      sensor: {},
      status: {},
      category: {},
      rules: {},
      event_types: {},
      close_reason: {},
      escalated: {
        0: "No",
        1: "Yes"
      }
    }),
    []
  );
  const [availableFilters, setAvailableFilters] =
    useState<IIncidentListAvailableFilters>(availableFiltersInitialState);
  const [currentFilters, setCurrentFilters] =
    useState<IIncidentListCurrentFilters>(currentFiltersInitialState);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [filtersChanged, setFiltersChanged] = useState<boolean>(false);

  const handleSearch: (text: string) => void = useCallback(
    (text: string) => setSearchText(text),
    []
  );
  const updateFilters: () => void = useCallback(async () => {
    try {
      const filters = await getPantherAvailableFilters(
        incidentsFiltersParametersMapper(currentFilters)
      );
      setAvailableFilters({
        ...availableFilters,
        ...incidentFiltersMapper(filters, isMdrRole)
      });
    } catch (error: any) {
      console.error(
        `Error getting incidents filters. Status ${error.status}. ${error}`
      );
    }
  }, [getPantherAvailableFilters, currentFilters, availableFilters, isMdrRole]);

  const showClient: boolean = useMemo(
    () => permissions.sections.filters.client.canSee(localUser.role),
    [permissions.sections.filters.client, localUser.role]
  );

  const toggleShowFilters = useCallback(
    () => setShowFilters(!showFilters),
    [showFilters]
  );

  const forceCloseFilters = useCallback(() => setShowFilters(false), []);

  const setClientFilter = useCallback(
    (client: string) => {
      setCurrentFilters({
        ...currentFilters,
        client: {
          id: client,
          caption: client
            ? availableFilters?.client[client]
            : SELECT_CLEAR_VALUE
        }
      });
    },
    [availableFilters, currentFilters]
  );

  const setHostnameFilter = useCallback(
    (hostname: string) => {
      setCurrentFilters({
        ...currentFilters,
        hostname: { id: hostname, caption: hostname || SELECT_CLEAR_VALUE }
      });
    },
    [currentFilters]
  );

  const setSensorFilter = useCallback(
    (hostname: string) => {
      setCurrentFilters({
        ...currentFilters,
        sensor: { id: hostname, caption: hostname || SELECT_CLEAR_VALUE }
      });
    },
    [currentFilters]
  );

  const setCloseReasonFilter = useCallback(
    (close_reason: string) => {
      setCurrentFilters({
        ...currentFilters,
        close_reason: {
          id: close_reason,
          caption:
            close_reason && availableFilters?.close_reason
              ? availableFilters.close_reason[close_reason]
              : SELECT_CLEAR_VALUE
        }
      });
    },
    [availableFilters.close_reason, currentFilters]
  );

  const setStatusFilter = useCallback(
    (status: string) => {
      setCurrentFilters({
        ...currentFilters,
        status: {
          id: status,
          caption: status
            ? typeof INCIDENT_FILTERS_MAP[status] === "string"
              ? INCIDENT_FILTERS_MAP[status]
              : INCIDENT_FILTERS_MAP[status][isMdrRole ? "mdr" : "nonMdr"]
            : SELECT_CLEAR_VALUE
        }
      });
    },
    [currentFilters, isMdrRole]
  );

  const setCategoryFilter = useCallback(
    (category: string) => {
      setCurrentFilters({
        ...currentFilters,
        category: {
          id: category,
          caption: category
            ? availableFilters?.category[category]
            : SELECT_CLEAR_VALUE
        }
      });
    },
    [availableFilters, currentFilters]
  );

  const setRulesFilter = useCallback(
    (rules: string) => {
      setCurrentFilters({
        ...currentFilters,
        rules: { id: rules, caption: rules || SELECT_CLEAR_VALUE }
      });
    },
    [currentFilters]
  );
  const setEventTypeFilter = useCallback(
    (event_type: string) => {
      setCurrentFilters({
        ...currentFilters,
        event_types: {
          id: event_type,
          caption: event_type || SELECT_CLEAR_VALUE
        }
      });
    },
    [currentFilters]
  );

  const setEscalatedFilter = useCallback(
    (escalated: string) =>
      setCurrentFilters({
        ...currentFilters,
        escalated: {
          id: escalated,
          caption: availableFilters.escalated[escalated] || SELECT_CLEAR_VALUE
        }
      }),
    [availableFilters, currentFilters]
  );

  const setCreationStartDateFilter = useCallback(
    (epoch: number, creationStartDate: string) => {
      if (dateDebounce.current) clearTimeout(dateDebounce.current);
      dateDebounce.current = setTimeout(() => {
        setCurrentFilters({
          ...currentFilters,
          creationStartDate: { epoch, caption: creationStartDate }
        });
      }, 500);
    },
    [currentFilters]
  );

  const setCreationEndDateFilter = useCallback(
    (epoch: number, creationEndDate: string) => {
      if (dateDebounce.current) clearTimeout(dateDebounce.current);
      dateDebounce.current = setTimeout(() => {
        setCurrentFilters({
          ...currentFilters,
          creationEndDate: { epoch, caption: creationEndDate }
        });
      }, 500);
    },
    [currentFilters]
  );

  const resetFilters = useCallback(() => {
    setCurrentFilters(currentFiltersInitialState);
    firstTimeSw.current = false;
    if (searchText) setSearchText("");
    else setFiltersChanged(true);
  }, [currentFiltersInitialState, searchText]);

  useEffect(() => {
    if (firstTimeSw.current) setFiltersChanged(true);
    else if (currentFilters?.creationStartDate?.epoch)
      firstTimeSw.current = true;
  }, [
    currentFilters.client,
    currentFilters.hostname,
    currentFilters.sensor,
    currentFilters.category,
    currentFilters.rules,
    currentFilters.status,
    currentFilters.escalated,
    currentFilters.creationStartDate,
    currentFilters.creationEndDate,
    currentFilters.close_reason,
    currentFilters.event_types
  ]);

  useEffect(() => {
    if (firstTimeSwSearchText.current)
      searchTextDebounce.current = setTimeout(
        () => setFiltersChanged(true),
        500
      );
    else firstTimeSwSearchText.current = true;
    return () => clearTimeout(searchTextDebounce.current);
  }, [searchText]); // eslint-disable-line react-hooks/exhaustive-deps

  const returnedInformation: IIncidentListFiltersContext = useMemo(
    () => ({
      availableFilters,
      currentFilters,
      showFilters,
      showClient,
      filtersChanged,
      searchText,
      setFiltersChanged,
      setClientFilter,
      setHostnameFilter,
      setSensorFilter,
      setStatusFilter,
      setCategoryFilter,
      setRulesFilter,
      setEventTypeFilter,
      setEscalatedFilter,
      setCreationStartDateFilter,
      setCreationEndDateFilter,
      resetFilters,
      toggleShowFilters,
      forceCloseFilters,
      updateFilters,
      handleSearch,
      setCloseReasonFilter,
      isSIEM: true
    }),
    [
      availableFilters,
      currentFilters,
      showFilters,
      showClient,
      filtersChanged,
      searchText,
      setClientFilter,
      setHostnameFilter,
      setSensorFilter,
      setStatusFilter,
      setCategoryFilter,
      setRulesFilter,
      setEventTypeFilter,
      setEscalatedFilter,
      setCreationStartDateFilter,
      setCreationEndDateFilter,
      resetFilters,
      toggleShowFilters,
      forceCloseFilters,
      updateFilters,
      handleSearch,
      setCloseReasonFilter
    ]
  );

  return (
    <IncidentPantherListFiltersContext.Provider value={returnedInformation}>
      {children}
    </IncidentPantherListFiltersContext.Provider>
  );
}

export default IncidentPantherListFiltersContext;
