import { IMenuItem } from "../components/menu/menu";
import {
  DEFAULT_ROWS_PER_PAGE,
  INCIDENT_FILTERS_MAP
} from "../constants/common.constants";
import {
  IIncidentListCurrentFilters,
  IIncidentListParameters,
  IIncidentsFilters
} from "../contexts/types/incident-list-filters.types";
import { IAnyPropertyNameAndStringValue } from "../types/common.types";
import alphabeticalOrderMapper from "./arrayMappers";

export const handlersMapper = (
  handlers: IAnyPropertyNameAndStringValue
): IMenuItem[] => {
  const result: IMenuItem[] = [];
  Object.keys(handlers).forEach((key: string) =>
    result.push({ id: key, value: handlers[key] })
  );
  return result;
};

export const closeReasonsMapper = (
  reasons: [string, string][]
): IMenuItem[] => {
  const result: IMenuItem[] = [];
  reasons?.forEach(([id, value]: any) => result.push({ id, value }));
  return result;
};

export const filtersMapper = (
  filters: IAnyPropertyNameAndStringValue
): IMenuItem[] => {
  const result: IMenuItem[] = [];
  Object.keys(filters).forEach((key: string) =>
    result.push({ id: key, value: filters[key] })
  );
  return result;
};

export const incidentFiltersMapper = (
  filters: { [key: string]: [] },
  isMdrRole: boolean
): IIncidentsFilters => {
  const result: IIncidentsFilters = {
    category: {},
    client: {},
    hostname: {},
    rules: {},
    severity: {},
    status: {},
    sensor: {},
    event_types: {},
    close_reason: {}
  };
  if (filters?.category)
    filters.category.forEach(({ option, name }) => {
      if (option) result.category[option] = name;
    });
  if (filters?.client)
    alphabeticalOrderMapper(filters.client, "name").forEach(
      ({ option, name }) => {
        if (option) result.client[option] = name;
      }
    );
  if (filters?.hostname)
    filters.hostname.forEach(({ option, name }) => {
      if (option) result.hostname[option] = name;
    });
  if (filters?.rules)
    filters.rules.forEach(({ option, name }) => {
      if (option) result.rules[option] = name;
    });
  if (filters?.severity)
    filters.severity.forEach(({ option, name }) => {
      if (option) result.severity[option] = name;
    });
  if (filters?.status)
    filters.status
      .filter((item: any) => item.option)
      .forEach(({ option }) => {
        if (option)
          result.status[option] =
            typeof INCIDENT_FILTERS_MAP[option] === "string"
              ? INCIDENT_FILTERS_MAP[option]
              : INCIDENT_FILTERS_MAP[option][isMdrRole ? "mdr" : "nonMdr"];
      });

  filters?.sensor?.forEach(({ option, name }) => {
    if (option && result.sensor) result.sensor[option] = name;
  });

  filters?.event_types?.forEach(({ option, name }) => {
    if (option && result.event_types) result.event_types[option] = name;
  });

  filters?.close_reason?.forEach(({ option, name }) => {
    if (option && result.close_reason) result.close_reason[option] = name;
  });
  return result;
};

export const incidentsListParametersMapper = (
  params: IIncidentListParameters,
  page: number,
  searchText: string
): string => {
  const {
    creationStartDate,
    creationEndDate,
    rowsPerPage = DEFAULT_ROWS_PER_PAGE,
    client,
    hostname,
    status,
    category,
    rules,
    escalated,
    event_types,
    sensor,
    close_reason
  }: IIncidentListParameters = params;

  let parameters = "";
  if (page) parameters += `page=${page}&per_page=${rowsPerPage}`;
  if (creationStartDate?.epoch)
    parameters += `&from=${creationStartDate?.epoch}`;
  if (creationEndDate?.epoch && creationStartDate?.epoch)
    parameters += `&to=${creationEndDate?.epoch}`;
  if (escalated?.id !== null)
    parameters += `&escalated=${escalated.id === "1" ? "true" : "false"}`;
  if (client?.id) parameters += `&client=${client?.id}`;
  if (hostname?.id) parameters += `&hostname=${hostname?.id}`;
  if (status?.id) parameters += `&status=${status?.id}`;
  if (category?.id) parameters += `&category=${category?.id}`;
  if (rules?.id) parameters += `&rule=${rules?.id}`;
  if (searchText) parameters += `&search=${searchText}`;
  if (event_types?.id) parameters += `&rule=${event_types?.id}`;
  if (sensor?.id) parameters += `&sensor=${sensor?.id}`;
  if (close_reason?.id) parameters += `&close_reason=${close_reason?.id}`;

  return parameters;
};

export const incidentsFiltersParametersMapper = (
  filters: IIncidentListCurrentFilters
): string => {
  const {
    client,
    hostname,
    status,
    category,
    rules,
    escalated,
    sensor,
    creationStartDate,
    creationEndDate,
    close_reason,
    event_types
  }: IIncidentListCurrentFilters = filters;

  let parameters = "?";
  if (creationStartDate?.epoch)
    parameters += `&from=${creationStartDate?.epoch}`;
  if (creationEndDate?.epoch && creationStartDate?.epoch)
    parameters += `&to=${creationEndDate?.epoch}`;
  if (escalated.id !== null)
    parameters += `&escalated=${escalated.id === "1" ? "true" : "false"}`;
  if (client?.id) parameters += `&client=${client?.id}`;
  if (hostname?.id) parameters += `&hostname=${hostname?.id}`;
  if (status?.id) parameters += `&status=${status?.id}`;
  if (category?.id) parameters += `&category=${category?.id}`;
  if (rules?.id) parameters += `&rule=${rules?.id}`;
  if (event_types?.id) parameters += `&rule=${event_types?.id}`;
  if (sensor?.id) parameters += `&sensor=${sensor?.id}`;
  if (close_reason?.id) parameters += `&close_reason=${close_reason?.id}`;

  return parameters;
};
