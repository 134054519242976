import React, { useContext, useState } from "react";
import "./eventDefaultActionBar.scss";
import {
  BOOKMARK_ICON,
  BOOKMARK_ON_ICON,
  EDR_VIEW_ICON,
  ERROR_ICON,
  INFORMATION_ICON,
  MESSAGE
} from "../../constants/common.constants";
import { IncidentContext } from "../../incident/incident";

import useEvent from "../useEvent";
import ActionBar from "../../components/actionBar/actionBar";
import Icon from "../../components/icon/icon";
import Tooltip, { tooltipTypes } from "../../components/tooltip/tooltip";
import SnackbarContext from "../../contexts/snackbar.context";
import { IEventDetailActionBarProps } from "../event.types";
import { buildUrl } from "../../utils/string.utils";
import {
  INCIDENT_DETAILS_URL,
  PANTHER_INCIDENT_DETAILS_URL
} from "../../constants/urls.constants";
import { IncidentInfoProviderContext } from "../../incident/incidentInformationProvider";

export default function EventDefaultActionBar({
  eventId,
  isRelevant,
  edrUrl,
  detectionId = "",
  alertId = ""
}: IEventDetailActionBarProps) {
  const { id: incidentId } = useContext(IncidentContext);
  const { showSnackbar } = useContext(SnackbarContext);

  const { isSIEM } = useContext(IncidentInfoProviderContext);

  const { bookmark } = useEvent(eventId);
  const [bookmarkOn, setBookmarkOn] = useState(isRelevant);

  const detailsUrl = buildUrl(
    isSIEM ? PANTHER_INCIDENT_DETAILS_URL : INCIDENT_DETAILS_URL,
    incidentId,
    eventId,
    isSIEM ? alertId : detectionId
  );

  const onClickBookmark = (event: any) => {
    event.stopPropagation();

    bookmark(!bookmarkOn)
      .then(() => {
        setBookmarkOn((prevState) => !prevState);
      })
      .catch((error) => {
        console.error(
          `Error updating bookmark attribute on event ${eventId} of incident ${incidentId}. Status ${error.status}. ${error}`
        );
        showSnackbar({
          text: "Error updating bookmark",
          type: MESSAGE.error,
          icon: ERROR_ICON
        });
      });
  };

  const onClickEDR = (event: any) => {
    event.stopPropagation();
    window.open(edrUrl, "_blank");
  };

  const onClickDetails = (event: any) => {
    event.stopPropagation();
    window.open(detailsUrl, "_blank");
  };

  return (
    <ActionBar>
      <>
        <div className="has-tooltip eventContainer__action-bookmark">
          <Icon
            image={bookmarkOn ? BOOKMARK_ON_ICON : BOOKMARK_ICON}
            alt="bookmark"
            onClick={onClickBookmark}
          />
          <Tooltip type={tooltipTypes.info} text="Bookmark" top />
        </div>
        <div className="has-tooltip eventContainer__action-edrView">
          <Icon image={EDR_VIEW_ICON} alt="edr" onClick={onClickEDR} />
          <Tooltip type={tooltipTypes.info} text="Go to EDR" top />
        </div>
        <div className="has-tooltip eventContainer__action-details">
          <Icon
            image={INFORMATION_ICON}
            alt="details"
            onClick={onClickDetails}
          />
          <Tooltip type={tooltipTypes.info} text="Details" top />
        </div>
      </>
    </ActionBar>
  );
}
