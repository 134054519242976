/* eslint-disable no-restricted-syntax */
/* eslint-disable consistent-return */
import React, { useCallback, useContext, useMemo } from "react";
import "./panther-incident-list.scss";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Table from "../components/table/table";
import Header from "../components/header/header";
import Icon from "../components/icon/icon";
import ActionBar from "../components/actionBar/actionBar";
import {
  FILTER_ICON,
  PANTHER_INCIDENTS_ICON,
  SYNC_ICON,
  TABLE,
  VARIANT
} from "../constants/common.constants";
import IncidentsFilters from "../incident-list/components/incidentsFilters";
import Pagination from "../components/table/pagination/pagination";
import Button from "../components/button/button";
import Tooltip, { tooltipTypes } from "../components/tooltip/tooltip";
import IncidentListFiltersContext from "../contexts/incident-list-filters.context-panther";
import Permissions from "../permissions/permissions";

import IPantherIncidentListContext from "../contexts/panther-incident-list.context";
import { buildUrl } from "../utils/string.utils";
import { PANTHER_INCIDENT_URL } from "../constants/urls.constants";

export default function PantherIncidents() {
  const {
    incidents,
    loading,
    uncheckAllIncidents,
    pages,
    rows,
    handleGotoPage,
    handleNextPage,
    handlePreviousPage,
    load,
    table
  } = useContext(IPantherIncidentListContext);
  const { toggleShowFilters, showFilters } = useContext(
    IncidentListFiltersContext
  );

  const localUser = useSelector((state: any) => state.user.profile);
  const { Incidents: permissions } = Permissions();
  const navigate = useNavigate();

  const headerAvailable: boolean = useMemo(
    () => permissions.sections.info.header.canSee(localUser.role),
    [localUser.role, permissions.sections.info.header]
  );

  const handleSync = useCallback(() => {
    if (headerAvailable) {
      uncheckAllIncidents();
    }
    load();
  }, [uncheckAllIncidents, headerAvailable, load]);

  const onClickCell = useCallback(
    (rowIndex: number, columnId: string) => {
      if (
        columnId !== TABLE.INCIDENTS.CELLS.MDR_USERS.check.accessor &&
        columnId !== TABLE.INCIDENTS.CELLS.MDR_USERS.assigned.accessor &&
        columnId !== TABLE.INCIDENTS.CELLS.MDR_USERS.actions.accessor
      ) {
        const { id } = incidents[rowIndex];
        navigate(buildUrl(PANTHER_INCIDENT_URL, id));
      }
    },
    [incidents, navigate]
  );

  return (
    <div className="incidentsContainer">
      <Header
        icon={<Icon image={PANTHER_INCIDENTS_ICON} alt="SIEM Events" />}
        title="SIEM events"
      >
        <ActionBar>
          <div className="has-tooltip incidentsSync">
            <img src={SYNC_ICON} alt="sync" onClick={handleSync} />
            <Tooltip type={tooltipTypes.info} text="Sync" />
          </div>
        </ActionBar>
      </Header>
      <section className="incidentsContainer__actionBar">
        <ActionBar>
          <div className="has-tooltip incidentsFilter">
            <Button
              onClick={toggleShowFilters}
              variant={VARIANT.secondary}
              active={showFilters}
            >
              <img src={FILTER_ICON} alt="Filters" />
            </Button>
            <Tooltip type={tooltipTypes.info} text="Apply Filters" />
          </div>
        </ActionBar>
      </section>

      <IncidentsFilters switchMethod />
      <section className="incidentsContainer__info">
        <Table
          instance={table.instance}
          onClickCell={onClickCell}
          isLoadingData={loading}
        />
        <Pagination
          pages={pages}
          rows={rows}
          onPrevious={handlePreviousPage}
          onNext={handleNextPage}
          onGoTo={handleGotoPage}
        />
      </section>
    </div>
  );
}
