/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */
import React, { useCallback, useMemo } from "react";
import "./detailLine.scss";
import CodeBlock from "../../components/codeBlock/codeBlock";
import { IAnyPropertyNameAndAnyValue } from "../../types/common.types";
import {
  EVENT_CHARS_LIMIT,
  MAX_LEVEL_DETAIL_LINE_DEPTH
} from "../../constants/common.constants";

type IDetailLineProps = {
  data: IAnyPropertyNameAndAnyValue;
  level?: number;
  hideLabels?: boolean;
};

function Paragraph({ value }: { value: string | number }) {
  return <p>{value}</p>;
}
function detailLineElement(value: any, recursiveComponent?: any) {
  switch (typeof value) {
    case "boolean":
      return <Paragraph value={value.toString()} />;
    case "object":
      return recursiveComponent ? (
        recursiveComponent()
      ) : (
        <CodeBlock data={JSON.stringify(value)} />
      );
    case "string":
      return value.length < EVENT_CHARS_LIMIT ? (
        <Paragraph value={value} />
      ) : (
        <CodeBlock data={value} />
      );
    default:
      return <Paragraph value={value} />;
  }
}

function isTruthyData(value: any) {
  if (typeof value === "object") {
    return Array.isArray(value) ? value.length > 0 : !!value;
  }
  return typeof value === "boolean" || !!value;
}

export default function DetailLine({
  data,
  level = 1,
  hideLabels = false
}: IDetailLineProps) {
  const className = useMemo(() => {
    let result = "detailLine";
    if (level !== 1) result += " detailLine-child";
    if (hideLabels) result += " is-array";
    return result;
  }, [hideLabels, level]);

  const getFieldType = useCallback(
    (value: any) =>
      detailLineElement(
        value,
        level >= MAX_LEVEL_DETAIL_LINE_DEPTH
          ? null
          : () => (
              <DetailLine
                data={value}
                level={level + 1}
                hideLabels={Array.isArray(value)}
              />
            )
      ),
    [level]
  );

  return (
    <div className={className}>
      {Object.keys(data || {}).map(
        (item: any, index: number) =>
          isTruthyData(data[item]) && (
            <div className={`detailLine__item level-${level}`} key={index}>
              {!hideLabels && (
                <label className="detailLine__item__label">{item}</label>
              )}
              {getFieldType(data[item])}
            </div>
          )
      )}
    </div>
  );
}
